import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

const pageTemplate = ({ data, pageContext }) => {
  const page = data.contentfulPodstrona

  const jsonLdDataWebsite = {
    "@context": "http://schema.org",
    "@type": "WebSite",
    url: "https://kamiko.studio/" + pageContext.slug,
    name: pageContext.name,
  }

  const jsonLdBreadcrumbs = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: "https://kamiko.studio/",
        name: "Kamiko",
      },
      {
        "@type": "ListItem",
        position: 2,
        item: "https://kamiko.studio/" + pageContext.slug,
        name: pageContext.name,
      },
    ],
  }

  const richtTextRenderOptions = {
    renderNode: {
      "embedded-asset-block": node => {
        const { gatsbyImageData } = node.data.target
        if (!gatsbyImageData) {
          return null
        }
        return <GatsbyImage image={gatsbyImageData} />
      },
    },
  }

  return (
    <Layout>
      <Seo
        title={page.name}
        url={"https://kamiko.studio/" + pageContext.slug}
        jsonLdData={[jsonLdDataWebsite, jsonLdBreadcrumbs]}
      />
      <div>
        {page.content && renderRichText(page.content, richtTextRenderOptions)}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulPodstrona(slug: { eq: $slug }) {
      name
      content {
        raw
      }
    }
  }
`

export default pageTemplate
